<template>
  <b-row class="match-height">
    <b-col
      lg="8"
      class="space_"
    >
      <div class="d-flex">
        <h2>環境設定</h2>
        <div class="ml-1">
          <b-badge variant="light-success">
            受講者･求職者
          </b-badge>
        </div>
      </div>
      <b-tabs align="left">
        <b-tab>
          <template #title>
            <feather-icon icon="PenToolIcon" />
            <span class="iconSmartPhone">基本設定</span>
          </template>
          <EditBasicSetting />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="BellIcon" />
            <span class="iconSmartPhone">公開情報･通知設定</span>
          </template>
          <EditScopeSetting />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="AlertTriangleIcon" />
            <span class="iconSmartPhone">ブロック設定</span>
          </template>
          <div class="ml-1 mb-2">
            ブロックしている講師のアイコンとニックネームが表示されます。<br>ブロックする場合は、受講者のプロフィールから「ブロックする」ボタンを押してください。
          </div>
          <b-card>
            ブロックしている人はいません。
          </b-card>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BCard, BBadge,
} from 'bootstrap-vue'
import EditBasicSetting from '@/components/student/basicSetting/EditBasicSetting.vue'
import EditScopeSetting from '@/components/student/scope/EditScopeSetting.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BBadge,
    EditBasicSetting,
    EditScopeSetting,
  },
}
</script>

<style scoped>
.space_ {
  margin: 0 auto;
}
</style>
